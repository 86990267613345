define("discourse/plugins/discourse-docs/discourse/components/docs-search", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "docs-search",
    onKeyDown(event) {
      if (event.key === "Enter") {
        this.set("searchTerm", event.target.value);
        this.onSearch(event.target.value);
      }
    },
    clearSearch() {
      this.set("searchTerm", "");
      this.onSearch("");
    }
  }, [["method", "onKeyDown", [_object.action]], ["method", "clearSearch", [_object.action]]]));
});