define("discourse/plugins/discourse-docs/discourse/components/docs-topic-list", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-docs/lib/get-docs"], function (_exports, _component, _object, _decorators, _getDocs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "docs-topic-list",
    urlPath: (0, _getDocs.getDocs)(),
    sortTitle(order) {
      return order === "title";
    },
    sortActivity(order) {
      return order === "activity";
    },
    sortListActivity() {
      this.sortBy("activity");
      return false;
    },
    sortListTitle() {
      this.sortBy("title");
      return false;
    }
  }, [["method", "sortTitle", [(0, _decorators.default)("order")]], ["method", "sortActivity", [(0, _decorators.default)("order")]], ["method", "sortListActivity", [_object.action]], ["method", "sortListTitle", [_object.action]]]));
});