define("discourse/plugins/discourse-docs/discourse/components/docs-category", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "",
    categoryName(category) {
      return this.site.categories.findBy("id", category.id).name;
    }
  }, [["method", "categoryName", [(0, _decorators.default)("category")]]]));
});