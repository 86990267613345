define("discourse/plugins/discourse-docs/discourse/templates/components/docs-category", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    href
    {{action this.selectCategory}}
    class="docs-item docs-category {{if this.category.active 'selected'}}"
  >
    {{d-icon (if this.category.active "times-circle" "far-circle")}}
  
    <span class="docs-item-id category-id">{{this.categoryName}}</span>
    <span class="docs-item-count category-count">{{this.category.count}}</span>
  </a>
  */
  {
    "id": "NKDbh+Nd",
    "block": "[[[11,3],[24,6,\"\"],[16,0,[29,[\"docs-item docs-category \",[52,[30,0,[\"category\",\"active\"]],\"selected\"]]]],[4,[38,1],[[30,0],[30,0,[\"selectCategory\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[[52,[30,0,[\"category\",\"active\"]],\"times-circle\",\"far-circle\"]],null]],[1,\"\\n\\n  \"],[10,1],[14,0,\"docs-item-id category-id\"],[12],[1,[30,0,[\"categoryName\"]]],[13],[1,\"\\n  \"],[10,1],[14,0,\"docs-item-count category-count\"],[12],[1,[30,0,[\"category\",\"count\"]]],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"action\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/components/docs-category.hbs",
    "isStrictMode": false
  });
});