define("discourse/plugins/discourse-docs/discourse/templates/components/docs-topic-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LoadMore @selector=".topic-list tr" @action={{this.loadMore}}>
    <table class="topic-list">
      <thead class="topic-list-header">
        <th role="button" class="topic-list-data" {{action "sortListTitle"}}>
          {{i18n "docs.column_titles.topic"}}
  
          {{#if this.sortTitle}}
            {{#if this.ascending}}
              {{d-icon "angle-up"}}
            {{else}}
              {{d-icon "angle-down"}}
            {{/if}}
          {{/if}}
        </th>
  
        <th role="button" class="topic-list-data" {{action "sortListActivity"}}>
          {{i18n "docs.column_titles.activity"}}
  
          {{#if this.sortActivity}}
            {{#if this.ascending}}
              {{d-icon "angle-up"}}
            {{else}}
              {{d-icon "angle-down"}}
            {{/if}}
          {{/if}}
        </th>
      </thead>
  
      <tbody class="topic-list-body">
        {{#each this.topics as |topic|}}
          {{docs-topic-list-item
            topic=topic
            urlPath=this.urlPath
            showExcerpt=this.showExcerpts
          }}
        {{/each}}
      </tbody>
    </table>
  </LoadMore>
  
  <ConditionalLoadingSpinner @condition={{this.loading}} />
  */
  {
    "id": "164dDQFO",
    "block": "[[[8,[39,0],null,[[\"@selector\",\"@action\"],[\".topic-list tr\",[30,0,[\"loadMore\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"table\"],[14,0,\"topic-list\"],[12],[1,\"\\n    \"],[10,\"thead\"],[14,0,\"topic-list-header\"],[12],[1,\"\\n      \"],[11,\"th\"],[24,\"role\",\"button\"],[24,0,\"topic-list-data\"],[4,[38,1],[[30,0],\"sortListTitle\"],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"docs.column_titles.topic\"],null]],[1,\"\\n\\n\"],[41,[30,0,[\"sortTitle\"]],[[[41,[30,0,[\"ascending\"]],[[[1,\"            \"],[1,[28,[35,4],[\"angle-up\"],null]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,4],[\"angle-down\"],null]],[1,\"\\n\"]],[]]]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[11,\"th\"],[24,\"role\",\"button\"],[24,0,\"topic-list-data\"],[4,[38,1],[[30,0],\"sortListActivity\"],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"docs.column_titles.activity\"],null]],[1,\"\\n\\n\"],[41,[30,0,[\"sortActivity\"]],[[[41,[30,0,[\"ascending\"]],[[[1,\"            \"],[1,[28,[35,4],[\"angle-up\"],null]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,4],[\"angle-down\"],null]],[1,\"\\n\"]],[]]]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"tbody\"],[14,0,\"topic-list-body\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,7],null,[[\"topic\",\"urlPath\",\"showExcerpt\"],[[30,1],[30,0,[\"urlPath\"]],[30,0,[\"showExcerpts\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null]],[\"topic\"],false,[\"load-more\",\"action\",\"i18n\",\"if\",\"d-icon\",\"each\",\"-track-array\",\"docs-topic-list-item\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/components/docs-topic-list.hbs",
    "isStrictMode": false
  });
});