define("discourse/plugins/discourse-docs/discourse/templates/docs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="docs">
    <span>
      <PluginOutlet
        @name="before-docs-search"
        @connectorTagName="div"
        @outletArgs={{hash
          selectCategory=(action "updateSelectedCategories")
          selectTag=(action "updateSelectedTags")
          tags=this.indexController.tags
          categories=this.indexController.categories
        }}
      />
    </span>
  
    <DocsSearch
      @searchTerm={{readonly this.indexController.searchTerm}}
      @onSearch={{action "performSearch"}}
    />
  
    {{outlet}}
  </div>
  */
  {
    "id": "JJJUNGbP",
    "block": "[[[10,0],[14,0,\"docs\"],[12],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"before-docs-search\",\"div\",[28,[37,1],null,[[\"selectCategory\",\"selectTag\",\"tags\",\"categories\"],[[28,[37,2],[[30,0],\"updateSelectedCategories\"],null],[28,[37,2],[[30,0],\"updateSelectedTags\"],null],[30,0,[\"indexController\",\"tags\"]],[30,0,[\"indexController\",\"categories\"]]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@searchTerm\",\"@onSearch\"],[[28,[37,4],[[30,0,[\"indexController\",\"searchTerm\"]]],null],[28,[37,2],[[30,0],\"performSearch\"],null]]],null],[1,\"\\n\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"plugin-outlet\",\"hash\",\"action\",\"docs-search\",\"readonly\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/docs.hbs",
    "isStrictMode": false
  });
});