define("discourse/plugins/discourse-docs/discourse/components/docs-topic-list-item", ["exports", "@ember/component", "@ember/template", "discourse-common/lib/raw-handlebars-helpers", "discourse-common/lib/raw-templates"], function (_exports, _component, _template, _rawHandlebarsHelpers, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "tr",
    classNameBindings: [":topic-list-item"],
    didInsertElement() {
      this._super(...arguments);
      this.renderTopicListItem();
    },
    renderTopicListItem() {
      const template = (0, _rawTemplates.findRawTemplate)("docs-topic-list-item");
      if (template) {
        this.set("topicListItemContents", (0, _template.htmlSafe)(template(this, _rawHandlebarsHelpers.RUNTIME_OPTIONS)));
      }
    }
  });
});